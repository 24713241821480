import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import asterisk from "../../assets/images/asterisk.png";
import axios from "axios";
import menu from "../../assets/images/menu.png";
import { useEffect, useState } from "react";
import { toUpperCase } from "../functions/upperCase";

const SelectedCategory = () => {
  let { id } = useParams();
  const [dishes, setDishes] = useState([]);
  const [categories, setCategories] = useState([]);

  console.log(dishes, "dishes")

  useEffect(() => {
    getCategories();
    getDishesByCategories();
  }, [id]);

  const getCategories = async () => {
    try {
      const { data } = await axios.get(
        "https://api.hum.agrotrust.io/api/v1/category"
      );
      setCategories(data);
    } catch (error) {
      window.alert(error.message);
    }
  };

  const getDishesByCategories = async () => {
    try {
      const { data } = await axios.get(
        `https://api.hum.agrotrust.io/api/v1/dish/dishList/${id}`
      );
      // console.log(data, "data of dishes")
      setDishes(data);
    } catch (error) {
      window.alert(error.message);
    }
  };
  return (
    <div className="flex flex-col bg-FBF2DB min-h-screen h-full w-full relative">
      {/* <img className="absolute top-10 right-6" src={menu} alt="" /> */}
      <div className="flex">
        <div className="m-4">
          {categories.map((category) => {
            return (
              <Link to={`/select-category/${category.id}`}>
                <div
                  key={category.id}
                  className={`flex font-bold text-xl text-yellow font-litmusSemiBold ${
                    category.id === id ? `` : `opacity-25`
                  }`}
                >
                  <span>
                    <img
                      className={`my-1.5 mx-1 ${
                        category.id === id ? `` : `hidden`
                      }`}
                      src={asterisk}
                      alt={asterisk}
                    />
                  </span>
                  <span className={`${category.id !== id ? `mx-4` : ``}`}>
                    {category.name}
                  </span>
                </div>
              </Link>
            );
          })}
        </div>
      </div>

      <div className="flex flex-wrap justify-center">
        {dishes.map((item) => {
          return (
            <Link to={`/description/${item.id}`}>
              <div className="flex flex-col rounded-xl m-4">
                <div className="w-34 h-34 bg-F2E4C2 rounded-xl mx-2">
                  <img
                    className="w-32 h-32 rounded-xl"
                    src={`https://hum-demo.infura-ipfs.io/ipfs/${item.image}`}
                    alt="img"
                  />
                </div>
                <div
                  key={item.id}
                  className="my-1 text-center text-sm font-volteSemibold text-sherpaBlue"
                >
                  {item.name}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default SelectedCategory;
