import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import CategorySelection from "./screens/category/CatergorySelection";
import Description from "./screens/category/DescriptionPage";
import MoreDetails from "./screens/category/MoreDetails";
import SelectedCategory from "./screens/category/SelectedCategory";
import SplashScreen from "./screens/components/SplashScreen";

function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => {
      setLoading(false);
    }, 4000);
    //4 seconds splash screen
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div className="flex">
      <div className="container m-auto max-w-lg bg-offYellow self-center">
        {loading ? (
          <SplashScreen />
        ) : (
          <Routes>
            <Route path="/" element={<CategorySelection />} />
            <Route path="/select-category/:id" element={<SelectedCategory />} />
            <Route path="/description/:id" element={<Description />} />
            <Route path="/farmers-details/:id" element={<MoreDetails />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        )}
      </div>
    </div>
  );
}

export default App;
