import { Link } from "react-router-dom";
import salad from "../../assets/images/saladimage.png";
import heroImage3x from "../../assets/images/heroImage3x.png";
import rightArrow from "../../assets/images/rightArrow.png";
import farmsPlaceholder from "../../assets/images/farms-placeholder.jpg";
import { useEffect, useState } from "react";
import menu from "../../assets/images/menu.png";
import axios from "axios";
import {toUpperCase} from "../functions/upperCase.js"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

const CategorySelection = () => {
  const [categories, setCategories] = useState([]);
  // console.log(categories, "set categories")
  const [fpos, setFpos] = useState([]);


  const getCategories = async () => {
    try {
      const { data } = await axios.get(
        "https://api.hum.agrotrust.io/api/v1/category"
      );
      setCategories(data);
    } catch (error) {
      window.alert(error.message);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const getFPOs = async () => {
    try {
      const { data } = await axios.get(
        "https://api.hum.agrotrust.io/api/v1/fpo"
      );
      setFpos(data);
    } catch (error) {
      window.alert(error.message);
    }
  };
  useEffect(() => {
    getFPOs();
  }, []);

  return (
    <div className="flex flex-col w-full bg-FBF2DB justify-center items-center relative">
      {/* <img className="absolute top-10 right-6" src={menu} alt="" /> */}
      <img className="h-3/4 w-full" src={heroImage3x} alt="heroImage" />

      <h1 className="p-2 mx-10 m-6 text-xl text-center text-DA9D19 font-litmusSemiBold ">
        {`What are you eating today?`}
      </h1>

      {categories.map((item) => {
        return (
          <Link to={`/select-category/${item.id}`}>
            <div className="flex w-44 h-44 m-2 bg-EDE1C1 rounded-2xl">
              <img
                className=""
                src={`https://hum-demo.infura-ipfs.io/ipfs/${item.image}`}
                alt="img"
              />
            </div>

            <div
              key={item.id}
              className="text-lg text-center mx-2 mb-8 font-volteSemibold text-sherpaBlue"
            >
              {item.name}
            </div>
          </Link>
        );
      })}

      <div className="flex flex-col h-full bg-sherpaBlue">
        <div className=" flex flex-col w-1/2 justify-self-center self-center text-center">
          <div className="text-offWhite font-volteSemibold text-xs py-10 mx-20">
            SOURCED FROM
          </div>
          <div className="font-litmusSemiBold text-yellow text-xl p-1 my-3">
            small scale farmer communities
          </div>
          <div className="text-offWhite font-volteSemibold text-xs">{`&`}</div>
          <div className="font-litmusSemiBold text-yellow text-xl p-1 my-3">
            artisanal suppliers across Maharashtra, Karnataka and Rajasthan
          </div>
        </div>

        <div>
          <CarouselProvider
            naturalSlideWidth={100}
            naturalSlideHeight={200}
            totalSlides={fpos.length}
            visibleSlides={1}
            currentSlide={1}
          >
            <Slider className="pr-[14%] pl-[14%]">
              {fpos.map((fpo) => {
                return (

                  <Slide key={fpo.id} index={fpo.id}>
                    <Link key={fpo.id} to={`/farmers-details/${fpo.id}`}>
                    <div className="flex shrink-0 flex-col mx-4">
                      <img
                        className="w-[253px] h-[318px] rounded-2xl hover:scale-105 ease-in-out "
                        src={`https://hum-demo.infura-ipfs.io/ipfs/${fpo.image}`}
                        alt="fpoholder"
                      />
                      <div className="font-volteSemibold text-center my-4 text-offWhite text-lg">
                        
                          {fpo.name}
                        
                        {/* <span>
                            <img
                              className="w-3 h-3"
                              src={rightArrow}
                              alt={rightArrow}
                            />
                          </span> */}
                      </div>
                    </div>
                    </Link>
                  </Slide>
                );
              })}
            </Slider>
          </CarouselProvider>
        </div>
      </div>
    </div>
  );
};

export default CategorySelection;
