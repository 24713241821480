import salads from "../../assets/images/salads.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import pinkLeftArrow from "../../assets/images/pinkLeftArrow.png";
import salad from "../../assets/images/salad3x.png";
import rightArrow from "../../assets/images/right-arrow.png";
import leftArrow from "../../assets/images/left-arrow.png";
import axios from "axios";
import { useEffect, useState } from "react";
import { toUpperCase } from "../functions/upperCase";

const Description = () => {
  const urlPrefix = `https://api.hum.agrotrust.io`;
  let navigate = useNavigate();
  const { id } = useParams();
  const [dishData, setDishData] = useState([]);
  const [nutrition, setNutrition] = useState([]);
  const [sourcesWithIngredients, setSourcesWithIngredients] = useState([]);

  console.log(dishData, "dish data");

  const getData = async () => {
    const response = await axios.get(`${urlPrefix}/api/v1/dish/${id}`);
    const dishData = response.data;
    setDishData(dishData);
    setNutrition(dishData.nutrition);

    const { ingredientList = [] } = dishData || {};

    const sourceUniv = {};
    for (const ingredient of ingredientList) {
      if (sourceUniv[ingredient.source]) {
        // If sourceUniv already contains the source, then add this ingredient to the list of ingredients in the source of sourceUniv
        sourceUniv[ingredient.source] = {
          ...sourceUniv[ingredient.source],
          ingredientList: [
            ...sourceUniv[ingredient.source].ingredientList,
            ingredient,
          ],
        };
      } else {
        // Otherwise create a new entry for the source id in sourceUniv and add the details of the source, and add the ingredient to the list of ingredients in it
        sourceUniv[ingredient.source] = {
          details: ingredient.sourceDetails,
          ingredientList: [ingredient],
        };
      }
    }
    setSourcesWithIngredients(Object.values(sourceUniv));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="flex flex-col align-center bg-pink overflow-x-hidden relative">
      {/* <img className="absolute top-10 right-6" src={menu} alt="" /> */}
      <div className="bg-sherpaBlue rounded-b-3xl">
        {/* back button */}
        <img
          className="w-5 h-5 pointer mt-10 ml-5 mb-3"
          onClick={() => navigate(-1)}
          src={pinkLeftArrow}
          alt="img"
        />
        <div className="flex flex-col">
          <div className="text-yellow text-xl ml-10 font-litmusSemiBold">
            {dishData?.categoryDetails?.name}
          </div>
          <div className="text-5xl text-FBF2DB ml-10 mt-4 my-4 font-litmusBold m-0.5">
            {dishData?.name}
          </div>
          <div className="relative float-right mt-10">
            <img
              className={`relative w-full h-full -right-32 overflow-hidden scale-x-150 scale-y-150 ${
                dishData?.id === "14" ||
                dishData?.id === "15" ||
                dishData?.id === "16"
                  ? "-rotate-45"
                  : "-rotate-0"
              }`}
              src={`https://hum-demo.infura-ipfs.io/ipfs/${dishData.image}`}
              alt={salad}
            />
          </div>

          <div className="flex justify-around mb-16 mt-32">
            <div>
              <div className="m-0.5 font-volteSemibold text-xs text-DA9D19">
                Calories
              </div>
              <div className="font-litmusSemiBold text-3xl text-pink m-0.5">
                {`${dishData.calories}`}
              </div>
            </div>
            <div>
              <div className="m-0.5 font-volteSemibold text-xs text-DA9D19">
                Protein
              </div>
              <div className="font-litmusSemiBold text-3xl text-pink m-1">
                {`${nutrition.protein}g`}
              </div>
            </div>
            <div>
              <div className="m-0.5 font-volteSemibold text-xs text-DA9D19">
                Carb
              </div>
              <div className="font-litmusSemiBold text-3xl text-pink m-0.5">
                {`${nutrition.carbs}g`}
              </div>
            </div>
            <div>
              <div className="m-0.5 font-volteSemibold text-xs text-DA9D19">
                Fat
              </div>
              <div className="font-litmusSemiBold text-3xl text-pink m-0.5">
                {`${nutrition.fat}g`}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-2xl p-1 text-center text-yellow font-litmusSemiBold my-8 mx-2">
        Sourced from farmers we know and supplier we trust.
      </div>

      <div className="flex flex-col justify-center items-center">
        {sourcesWithIngredients?.map((source) => {
          // console.log(source, "source")
          return (
            <div>
              <div className="flex font-litmusSemiBold text-2xl justify-center align-center text-sherpaBlue">
                <Link
                  key={source.details.id}
                  to={`/farmers-details/${source.details.id}`}
                >
                  {source.details.name}
                </Link>
                <span>
                  <img
                    className="w-5 h-4 mx-1 my-1"
                    src={rightArrow}
                    alt={rightArrow}
                  />
                </span>
              </div>
              <div className="flex flex-wrap justify-center">
                {source.ingredientList.map((item) => {
                  return (
                    <>
                      <div className="flex flex-col rounded-xl m-6 ">
                        <div className="w-32 h-32">
                          <img
                            className="rounded-xl"
                            src={`https://hum-demo.infura-ipfs.io/ipfs/${item.image}`}
                            alt={salad}
                          />
                        </div>
                        <div
                          key={item.id}
                          className="my-1 text-sm font-volteSemibold text-sherpaBlue"
                        >
                          {item.name}
                        </div>
                        <div className="text-sm font-volteMedium text-sherpaBlue">
                          {item?.temperature?.minT === "NA" ||
                          item?.temperature?.maxT === "NA"
                            ? ""
                            : (`Temperate: ${item?.temperature?.minT}C - ${item?.temperature?.maxT}C`)}
                        </div>
                        <div className="text-sm font-volteMedium text-sherpaBlue">


                          {item?.humidity?.minH === "NA" ||
                          item?.humidity?.maxH === "NA"
                            ? ""
                            : (`Humidity: ${item?.humidity?.minH}% - ${item?.humidity?.maxH}%`)}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Description;
