import pinkLeftArrow from "../../assets/images/pinkLeftArrow.png";
import menu from "../../assets/images/menu.png";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { toUpperCase } from "../functions/upperCase";

const MoreDetails = () => {
  const urlPrefix = `https://api.hum.agrotrust.io`;
  let navigate = useNavigate();
  const { id } = useParams();
  const [FPODetails, setFPODetails] = useState({});
  const [ingredients, setIngredients] = useState([]);

  const getFPO = async () => {
    const response = await axios.get(`${urlPrefix}/api/v1/fpo/${id}`);
    setFPODetails(response.data);
  };

  useEffect(() => {
    try {
      getFPO();
    } catch (error) {
      window.alert(error.message);
    }
  }, []);

  const getIngredientsByFPO = async () => {
    const response = await axios.get(`${urlPrefix}/api/v1/fpo/source/${id}`);

    setIngredients(response.data);
  };

  useEffect(() => {
    try {
      getIngredientsByFPO();
    } catch (error) {
      window.alert(error.message);
    }
  }, []);

  return (
    <div className="flex flex-col w-full h-screen">
      <div className="relative">
        <div className="bg-sherpaBlue">
          {/* back button */}

          <div className="bg-sherpaBlue opacity-40">
            <img
              src={`https://hum-demo.infura-ipfs.io/ipfs/${FPODetails.image}`}
              alt="img"
            />
          </div>
          <div>
            <img
              className="absolute w-5 h-5 top-10 left-5"
              onClick={() => navigate(-1)}
              src={pinkLeftArrow}
              alt="img"
            />
          </div>
          {/* <img className="absolute top-10 right-6" src={menu} alt="" /> */}
          <div className="bg-sherpaBlue">
            <div className="w-30 text-5xl text-FBF2DB font-litmusBold top-3/4 -mt-4 mx-6 mb-4 p-1">
              {FPODetails.name}
            </div>

            <div className="w-80 text-xl text-D99C1B font-litmusSemiBold m-auto pb-4 border-b-2">
              {FPODetails.description1}
            </div>
            <div className="w-80 text-xs text-A1BC9D font-volteSemibold m-auto mt-8">
              Location
            </div>

            <div className="w-80 text-lg text-FBF2DB font-litmusSemiBold m-auto mb-8">
              {FPODetails.location}
            </div>

            <div className="w-80 m-auto text-D99C1B font-volteMedium">
              <p className="p-4">{FPODetails.description2}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-FBF2DB justify-center items-center w-full">
        <div className="w-72 text-2xl text-yellow font-litmusSemiBold my-10">
          Ingredients we source from {FPODetails.name}
        </div>

        <div className="flex flex-wrap justify-center overflow-hidden">
          {ingredients.map((item) => {
            // return <div></div>
            return (
              <div className="flex bg-FBF2DB">
                <div className="flex flex-col m-4">
                  <div className="w-36 h-36">
                    <img
                      className="rounded-t-xl"
                      src={`https://hum-demo.infura-ipfs.io/ipfs/${item.image}`}
                      alt="img"
                    />
                  </div>

                  <div className="text-sm font-litmusSemiBold text-sherpaBlue">
                    {item.name}
                  </div>
                  <div className="text-xs font-volteMedium text-sherpaBlue ">
                    {item.temperature[0] === "NA" ||
                    item.temperature[1] === "NA"
                      ? ""
                      : `Temperate: ${item.temperature[0]}C - ${item.temperature[1]}C`}
                  </div>
                  <div className="text-xs font-volteMedium text-sherpaBlue ">
                    {item?.humidity?.[0] === "NA" || item?.humidity?.[1] === "NA"
                      ? ""
                      : `Humidity: ${item.humidity[0]}% - ${item.humidity[1]}%`}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MoreDetails;
