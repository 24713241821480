import React, { useState } from "react";
import humLogo from "../../assets/images/humLogo.png";

const SplashScreen = () => {
  return (
    <div className="bg-pink relative">
      <div className="flex h-screen justify-center items-center">
        <img className="w-52 h-80" src={humLogo} alt={humLogo} />
      </div>
    </div>
  );
};

export default SplashScreen;
